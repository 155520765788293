<template>
  <div>
    <b-row>
      <b-col md="12">
        <date-range-picker v-if="hasPer('account.category.projects.daily')"
                           ref="picker"
                           opens=false
                           :timePicker=false
                           :timePicker24Hour=false
                           :showWeekNumbers=true
                           :showDropdowns=true
                           format='mm/dd/yyyy'
                           class="w-100 bg-white mb-2"
                           direction="center"
                           :autoApply=true
                           v-model="dateRangeTotal"
                           :linkedCalendars=true
                           customRangeLabel= 'Custom Range'
                           @update="getDailyDiskBranch(), getDailyDisk()"
        >
        </date-range-picker>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12" v-if="hasPer('account.category.daily')">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Daily Disk</h4>
          </template>
          <template v-slot:body>
            <div v-if="isBusyTableDisk" class="text-center">
              <b-spinner small type="grow"></b-spinner>
              Loading...
            </div>
            <b-table striped responsive  class="mb-0 table-borderless text-center" :items="dailyDiskData" v-else>
              <template #thead-top>
                <b-tr>
                  <b-th colspan="4" class="text-center">Total Revenue </b-th>
                  <b-th colspan="4" class="text-center">Total Refund</b-th>
                  <b-th colspan="2" class="text-center">Total Expenses</b-th>
                  <b-th class="text-center">Withdraw</b-th>
                  <b-th colspan="4" class="text-center">Total Transfer</b-th>
                  <b-th colspan="4" class="text-center">Net Profit</b-th>
                </b-tr>
              </template>
              <template #bottom-row>
                <b-td colspan="4" class="text-center">{{ totalRow.totalRevenue }} </b-td>
                <b-td colspan="4" class="text-center">{{ totalRow.totalRefund }}</b-td>
                <b-td colspan="2" class="text-center">{{ totalRow.totalExpenses }}</b-td>
                <b-td class="text-center">{{ totalRow.withdraw }}</b-td>
                <b-td colspan="4" class="text-center">{{ totalRow.totalTransfer }}</b-td>
                <b-td colspan="4" class="text-center">{{ totalRow.netProfit }}</b-td>
              </template>
            </b-table>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="12" v-if="hasPer('account.category.daily')">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Daily Safe</h4>
          </template>
          <template v-slot:body>
            <div v-if="isBusyTable" class="text-center">
              <b-spinner small type="grow"></b-spinner>
              Loading...
            </div>
            <b-table striped responsive  class="mb-0 table-borderless text-center" :items="dailyDisk" v-else>
              <template #thead-top>
                <b-tr>
                  <b-th colspan="4" class="text-center">Total Revenue </b-th>
                  <b-th colspan="4" class="text-center">Total Refund</b-th>
                  <b-th colspan="2" class="text-center">Total Expenses</b-th>
                  <b-th class="text-center">Withdraw</b-th>
                  <b-th colspan="4" class="text-center">Total Transfer</b-th>
                  <b-th colspan="4" class="text-center">Net Profit</b-th>
                </b-tr>
              </template>
              <template #bottom-row>
                <b-td colspan="4" class="text-center">{{ totalRow.totalRevenue }} </b-td>
                <b-td colspan="4" class="text-center">{{ totalRow.totalRefund }}</b-td>
                <b-td colspan="2" class="text-center">{{ totalRow.totalExpenses }}</b-td>
                <b-td class="text-center">{{ totalRow.withdraw }}</b-td>
                <b-td colspan="4" class="text-center">{{ totalRow.totalTransfer }}</b-td>
                <b-td colspan="4" class="text-center">{{ totalRow.netProfit }}</b-td>
              </template>
            </b-table>
          </template>
        </iq-card>
      </b-col>
      <b-col lg='12'>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Balance</h4>
          </template>
          <template v-slot:body>
            <div v-if="loadWidget" class="text-center">
              <b-spinner small type="grow"></b-spinner>
              Loading...
            </div>
            <b-row v-else>
              <b-col lg="4"  class="mb-2" v-for="(data, key, i) in allWidget" :key="key">
                <div  :class="[`color_${i}`,'text-black p-2 iq-border-radius-5', data < 0 ? 'bg-danger': '']">
                  <h5 class="text-black font-weight-bolder">{{key | textFormat}}</h5>
                  <span v-if="data" class="font-weight-bold">{{data}}</span>
                  <span v-else class="font-weight-bold">0.00</span>
                </div>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { core } from '../../config/pluginInit'
import accountantServices from '../../services/AccountsCategories/accountsCategories'
export default {
  name: 'accountingBalance',
  mounted () {
    core.index()
  },
  data () {
    return {
      isBusyTable: true,
      allWidget: '',
      loadWidget: false,
      dailyDisk: [],
      dailyDiskData: [],
      isBusyTableDisk: true,
      totalRow: {
        totalRevenue: 0,
        totalRefund: 0,
        totalExpenses: 0,
        withdraw: 0,
        totalTransfer: 0,
        netProfit: 0
      },
      totalRowDisk: {
        totalRevenue: 0,
        totalRefund: 0,
        totalExpenses: 0,
        withdraw: 0,
        totalTransfer: 0,
        netProfit: 0
      },
      dateRangeTotal: {
        startDate: new Date(),
        endDate: new Date()
      }
    }
  },
  created () {
    if (this.hasPer('account.category.daily')) {
      this.getDailyDisk()
    }
    if (this.hasPer('account.category.daily')) {
      this.getDailyDiskBranch()
    }
    this.getAllWidet()
  },
  filters: {
    textFormat (value) {
      return value.replaceAll('_', ' ')
    }
  },
  methods: {
    getDailyDisk () {
      this.dailyDisk = []
      this.$store.dispatch('getDailyDisk', { type: 'accountant', startDate: new Date(this.dateRangeTotal.startDate).toJSON().slice(0, 10), endDate: new Date(this.dateRangeTotal.endDate).toJSON().slice(0, 10) }).then(res => {
        const totalRevenue = parseFloat(res.data.data.revenue_cash) + parseFloat(res.data.data.revenue_visa) + parseFloat(res.data.data.revenue_vodafone_cash) + parseFloat(res.data.data.revenue_paymob)
        const totalRefund = parseFloat(res.data.data.refund_cash) + parseFloat(res.data.data.refund_visa) + parseFloat(res.data.data.refund_vodafone_cash) + parseFloat(res.data.data.refund_paymob)
        const totalExpenses = parseFloat(res.data.data.expense_cash) + parseFloat(res.data.data.expense_visa)
        const withdraw = res.data.data.withdraw
        const totalTransfer = parseFloat(res.data.data.transfer_cash) + parseFloat(res.data.data.transfer_visa) + parseFloat(res.data.data.transfer_vodafone_cash) + parseFloat(res.data.data.transfer_paymob)
        this.totalRow = {
          totalRevenue: totalRevenue,
          totalRefund: totalRefund,
          totalExpenses: totalExpenses,
          withdraw: withdraw,
          totalTransfer: totalTransfer,
          netProfit: parseFloat(totalRevenue) - parseFloat(totalRefund) - parseFloat(totalExpenses) -
              parseFloat(withdraw)
        }
        this.dailyDisk = [{
          ...res.data.data,
          'Net Cash': parseFloat(res.data.data.revenue_cash) - (parseFloat(res.data.data.expense_cash) +
              parseFloat(res.data.data.refund_cash) + parseFloat(res.data.data.withdraw)),
          'Net Visa': parseFloat(res.data.data.revenue_visa) - (parseFloat(res.data.data.expense_visa) +
              parseFloat(res.data.data.refund_visa)),
          'Net Vodafone cash': parseFloat(res.data.data.revenue_vodafone_cash) -
              (parseFloat(res.data.data.refund_vodafone_cash)),
          'Net Paymob': parseFloat(res.data.data.revenue_paymob) -
              (parseFloat(res.data.data.refund_paymob))
        }]
      }).finally(() => {
        this.isBusyTable = false
      })
    },
    getDailyDiskBranch () {
      this.dailyDiskData = []
      this.$store.dispatch('getDailyDisk', {
        type: 'branch',
        startDate:
            new Date(this.dateRangeTotal.startDate).toJSON().slice(0, 10),
        endDate: new Date(this.dateRangeTotal.endDate).toJSON().slice(0, 10)
      }).then(res => {
        const totalRevenue = parseFloat(res.data.data.revenue_cash) + parseFloat(res.data.data.revenue_visa) + parseFloat(res.data.data.revenue_vodafone_cash) + parseFloat(res.data.data.revenue_paymob)
        const totalRefund = parseFloat(res.data.data.refund_cash) + parseFloat(res.data.data.refund_visa) + parseFloat(res.data.data.refund_vodafone_cash) + parseFloat(res.data.data.refund_paymob)
        const totalExpenses = parseFloat(res.data.data.expense_cash) + parseFloat(res.data.data.expense_visa)
        const withdraw = res.data.data.withdraw
        const totalTransfer = parseFloat(res.data.data.transfer_cash) + parseFloat(res.data.data.transfer_visa) + parseFloat(res.data.data.transfer_vodafone_cash) + parseFloat(res.data.data.transfer_paymob)
        this.totalRowDisk = {
          totalRevenue: totalRevenue,
          totalRefund: totalRefund,
          totalExpenses: totalExpenses,
          withdraw: withdraw,
          totalTransfer: totalTransfer,
          netProfit: totalRevenue - totalRefund - totalExpenses - withdraw
        }
        this.dailyDiskData = [{
          ...res.data.data,
          'Net Cash': parseFloat(res.data.data.revenue_cash) - (parseFloat(res.data.data.expense_cash) +
              parseFloat(res.data.data.refund_cash) +
              parseFloat(res.data.data.withdraw)),
          'Net Visa': parseFloat(res.data.data.revenue_visa) - (parseFloat(res.data.data.expense_visa) +
              parseFloat(res.data.data.refund_visa)),
          'Net Vodafone cash': parseFloat(res.data.data.revenue_vodafone_cash) -
              (parseFloat(res.data.data.refund_vodafone_cash)),
          'Net Paymob': parseFloat(res.data.data.revenue_paymob) -
              (parseFloat(res.data.data.refund_paymob))
        }]
        // console.log('this.dailyDisk', this.dailyDisk)
      }).finally(() => {
        this.isBusyTableDisk = false
      })
    },
    getAllWidet () {
      this.loadWidget = true
      accountantServices.getReportsFinancialWidget().then(res => {
        // console.log('res', res)
        this.allWidget = res.data.data
        this.loadWidget = false
      })
    }
  },
  watch: {
  }
}
</script>
<style>
.nav-link.router-link-active{
  border-bottom: 2px solid var(--iq-primary) !important;
  color: var(--iq-primary) !important;
}
.colorSpan{
  width: 20px;
  height: 20px;
}
[dir=ltr][mode=light] .nav-tabs {
  background: #fff !important;
  border-radius: 10px !important;
}
.color_0 {
background: #ffece7;
}
.color_1 {
  background: #f7f2fb;
}
.color_2 {
background: #ffece7;
}
.color_3 {
background: #f7f2fb;
}
.color_4 {
  background: #ffece7;
}
.color_5 {
  background: #f7f2fb;
}
.color_6 {
  background: #ffece7;
}
.color_7 {
  background: #f7f2fb;
}
</style>
